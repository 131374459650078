.login {
  height: 100%;
  transform: translateZ(0);

  body {
    display: flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    color: $gray-800;

    .form-signin {
      width: 100%;
      max-width: 330px;
      padding: 15px;
      margin: 0 auto;

      .logo {
        width: 140px;
      }

      .checkbox {
        font-weight: 400;
      }

      .form-control {
        font-size: 16px;

        &:focus {
          z-index: 2;
        }
      }

      input[type="email"] {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      input[type="password"] {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
}
