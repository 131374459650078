.navbar {

  &-dark {
    color: $white;

    .navbar-nav {

      .nav-link {
        font-size: 1rem;
        color: $white;

        &::after {
          display: none;
        }
      }
    }
  }

  &.ticker-nav {
    font-size: 1rem;
    color: $white;

    a {
      color: $secondary;
    }
  }

  .input-group {

    input {
      border-right: 0 none;
    }

    .input-group-text {
      color: $gray-600;
      background: $white;
    }
  }

  .navbar-brand {

    img {
      height: 28px;
    }
  }
}
