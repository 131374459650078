//the purpose of this file is to apply general style for tags and the very basic css classes.

html,
body {
  //mobile-first approach here
  font-size: $font-size-base;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-base * 1.1;
  }
}

body {

  //Find more Google Fonts https://fonts.google.com
  font-family: Mukta, sans-serif;
  background-color: $body-bg;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Raleway, sans-serif;
  }

  //Bootwatch custom

  .bs-component {
    padding: $list-inline-padding;
  }

  blockquote {
    @extend .blockquote;
  }

  pre,
  code {
    padding: 2px 1em;
    overflow: hidden;
    font-family: Consolas, "Andale Mono WT", "Andale Mono", "Bitstream Vera Sans Mono", "Nimbus Mono L", Monaco, "Courier New", monospace;
    font-size: 1em;
    line-height: 2rem;
    color: $gray-900;
    text-align: left;
    word-wrap: normal;
    direction: ltr;
    background-color: $gray-200;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-shadow: inset 0 0 .5em $gray-400;
  }
}
