//
// Variables
// --------------------------------------------------
$em-base: 16; // asuming browser default 16px

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// FontAwesome override
$fa-font-path: "../fonts" !default;

//
// Color system
//

// stylelint-disable
$white:         #fff;
$gray-100:      #f8f9fa;
$gray-200:      #e9ecef;
$gray-300:      #dee2e6;
$gray-400:      #ced4da;
$gray-500:      #adb5bd;
$gray-600:      #6c757d;
$gray-700:      #495057;
$gray-800:      #343a40;
$gray-900:      #212529;
$black:         #000;

$blue:          #004f94;
$indigo:        #6610f2;
$purple:        #6f42c1;
$pink:          #e83e8c;
$red:           #dc3545;
$orange:        #ed6d0f;
$yellow:        #ffc107;
$green:         #28a745;
$teal:          #20c997;
$cyan:          #17a2b8;

$primary:       $blue;
$secondary:     $orange;

//$primary:       $indigo;
//$secondary:     darken($yellow, 15%);

$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $white;
$dark:          $gray-800;

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8%;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900;
$yiq-text-light: $white;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:              true;
$enable-rounded:            true;
$enable-shadows:            false;
$enable-gradients:          false;
$enable-transitions:        true;
$enable-hover-media-query:  false; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:       true;
$enable-print-styles:       true;

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $gray-100;
$body-color:                $gray-900;

// Links
//
// Style anchor elements.

$link-color:                $primary;
$link-decoration:           none;
$link-hover-color:          darken($link-color, 15%);
$link-hover-decoration:     underline;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem;


// Grid breakpoints
//
// Define here the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           30px;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              1.5;
$line-height-sm:              1.5;

$border-width:                1px;
$border-color:                $gray-300;

$border-radius:               0.25rem;
$border-radius-lg:            0.3rem;
$border-radius-sm:            0.2rem;

$component-active-color:      $white;
$component-active-bg:         $primary;

$caret-width:                 0.3em;

$transition-speed:            0.2s;
$transition-base:             all $transition-speed ease-in-out;
$transition-fade:             opacity 0.15s linear;
$transition-collapse:         height 0.35s ease;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-lg:                calc($font-size-base * 1.25);
$font-size-sm:                calc($font-size-base * 0.875);

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.5;

$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

$spacer: 1rem !default;

$headings-margin-bottom:      calc($spacer / 2);
$headings-font-family:        inherit;
$headings-font-weight:        500;
$headings-line-height:        1.2;
$headings-color:              inherit;

$blockquote-small-color:      $white;
