//make additional changes to Buttons

.btn {
  position: relative;

  &-secondary {
    color: $white;

    &:hover {
      color: $white;
    }
  }

  &-gradient {
    color: $gray-100;
    background-image: linear-gradient(120deg, $secondary 10%, lighten($secondary, 10%) 50%, darken($secondary, 5%) 90%);
    transition: all .6 ease-out;

    &:hover {
      color: $white;
      background-color: $secondary;
      background-image: linear-gradient(120deg, $secondary 0%, $secondary 100%);
    }
  }

  &__ripple {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: transparent;

    .btn-circle {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      background: rgba(10, 10, 10, .123);
      border-radius: 40%;
      opacity: 0;
      transform: translate(-50%, -50%);

      &.is-active {
        animation: btnRipple $transition-speed * 3 ease-out;
      }
    }
  }
}

/**
 * Animation: Ripple
 * --------------------------------------------------
 */

@include keyframes(btnRipple) {

  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  100% {
    width: 200%;
    padding-bottom: 200%;
    opacity: .25;
  }
}

// navbar-toggle

.navbar-toggler.animated {
  position: relative;
  width: 3rem;
  height: 3rem;
  padding: .5rem;
  background: transparent;
  border: 1px solid transparent;
  outline: 0;

  &:focus,
  &:active {
    border: 0 none;
    outline: 0;
  }

  // default status

  span {
    position: relative;
    left: 0;
    display: block;
    width: 1.9rem;
    height: 2px;
    margin-top: .1rem;
    margin-bottom: .5rem;
    background-color: $white;
    opacity: 1;

    @include transform(rotate(0deg));

    &:nth-child(1),
    &:nth-child(3) {
      @include transition(transform .35s ease-in-out);
    }
  }

  // active

  &:not(.collapsed) {

    span {

      &:nth-child(1) {
        position: absolute;
        top: 1rem;
        left: 8px;
        opacity: .9;

        @include transform(rotate(135deg));
      }

      &:nth-child(2) {
        height: 12px;
        visibility: hidden;
        background-color: transparent;
      }

      &:nth-child(3) {
        position: absolute;
        top: 1rem;
        left: 8px;
        opacity: .9;

        @include transform(rotate(-135deg));
      }
    }
  }
}
