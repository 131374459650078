form {
  color: inherit;
}

// switcher

.switch {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.4rem;
  margin: 0;

  input {
    width: 0;
    height: 0;
    opacity: 0;
  }

  .slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    background-color: $gray-300;
    transition: .4s;

    &::before {
      position: absolute;
      bottom: .2rem;
      left: .2rem;
      width: 1rem;
      height: 1rem;
      content: "";
      background-color: $white;
      transition: .4s;
    }

    // Rounded sliders

    &.round {
      border-radius: 1.8rem;

      &::before {
        border-radius: 50%;
      }
    }
  }

  input:checked + .slider {
    background-color: lighten($primary, 15%);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $white;
  }

  input:checked + .slider::before {
    transform: translateX(1.5rem);
    transform: translateX(1.5rem);
    transform: translateX(1.5rem);
  }
}
