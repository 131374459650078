footer {
  padding: rem(20px) 0;
  color: $white;
  //coding here all footer design, example below
  background: $dark;

  a {
    color: $secondary;
    text-decoration: none;

    &:hover {
      color: lighten($secondary, 10%);
    }
  }

  p {
    color: $white;
  }
}
