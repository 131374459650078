@include keyframes( spin ) {

  0% {

    @include transform( rotate(0) );
  }

  100% {

    @include transform( rotate(360deg) );
  }
}

.infinite_spinner {
  position: relative;

  &_large {
    width: 80px;
    height: 80px;
  }

  &_medium {
    width: 52px;
    height: 52px;
  }

  &_small {
    width: 24px;
    height: 24px;
  }

  &_spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include transform(rotate(-45deg));

    @include animation(1.2s cubic-bezier(0.25,0.29,0.54,0.86) 0s infinite normal none spin);
  }

  &_tail {

    @include animation(1.2s cubic-bezier(0.41,0.24,0.64,0.69) 0s infinite normal none spin);
  }

  &_bg {
    stroke-width: 8;
    fill: none;
    opacity: 0.2;
    stroke: grey;
  }

  &_bg_white {
    stroke: #fff;
  }

  &_path {
    fill: none;
    stroke-width: 8;
    stroke-dasharray: 55, 200;
    stroke-dashoffset: 90;
    stroke-linecap: round;
  }

  &_white {
    stroke: #fff;
  }

  &_primary {
    stroke: $primary;
  }

  &_secondary {
    stroke: $secondary;
  }

  &_success {
    stroke: $success;
  }

  &_info {
    stroke: $info;
  }

  &_fast {
    animation-duration: 0.8s;
  }

  &_fast2x {
    animation-duration: 0.4s;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__container--50vh {
    height: 50vh;
  }
}
